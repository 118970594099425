<p-toast />

<div *ngIf="isStaging" class="w-full flex p-3 bg-yellow-100 text-center justify-content-center">
  <div class="mr-3">
    <div class="text-yellow-900 font-medium text-l">Staging environment</div>
  </div>
</div>

<ng-container *transloco="let t;">
  <div class="px-6 surface-overlay relative">
    <div class="py-3 lg:py-3 flex align-items-center justify-content-between lg:static">
      <img src="assets/images/ShopChat-logo.png" draggable="false" alt="Logo" height="40" class="mr-0 lg:mr-6">
  
      <div
        class="hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li>
            <a (click)="toggleFullScreen()"
              class="p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                {{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}
              </span>
              <span class="block lg:hidden font-medium">Inbox</span>
            </a>
          </li>
          <li>
            <a (click)="langMenu.toggle($event)"
              class="p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <img [src]="'assets/flags/' + activeLang + '.png'" height="32" draggable="false" alt="Lang">
              <span class="block lg:hidden font-medium">Notifications</span>
            </a>
          </li>
          <li class="border-top-1 surface-border lg:border-top-none">
            <a (click)="menu.toggle($event)"
              class="relative p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <p-avatar *ngIf="avatarLabel" [label]="avatarLabel" size="normal"
                [style]="{ 'background-color': '#ece9fc', color: '#2a1261' }" shape="circle" />
              <img *ngIf="!avatarLabel" src="assets/images/user.png" width="28" class="mr-3 lg:mr-0">
            </a>
          </li>
        </ul>
        <p-menu #langMenu [model]="langOptions" [popup]="true"></p-menu>
        <p-menu #menu [model]="profileOptions" [popup]="true">
          <ng-template *ngIf="user" pTemplate="start">
            <p class="px-3 py-2 m-0">
              <b>{{ user.name }} {{ user.surname }}</b>
              <br>
              <span class="text-sm text-500">{{ user.email }}</span>
              <p-badge *ngIf="user.admin" styleClass="mt-2" [value]="'Account admin'" severity="warning" />
            </p>
          </ng-template>
        </p-menu>
      </div>
    </div>
    <div
      class="py-3 hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 border-top-1 surface-border">
      <div class="flex justify-content-between">
        <ul class="list-none p-0 m-0 flex lg:align-items-center flex-column lg:flex-row select-none">
          <li *ngIf="user?.admin">
            <a [routerLink]="'/admin/users'"
              [ngClass]="{'text-blue-500 surface-100': router.url == '/admin/users', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/admin/users'}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                person
              </span>
              <span class="ml-2">Users</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="!user?.admin">
            <a [routerLink]="'/'"
              [ngClass]="{'text-blue-500 surface-100': router.url == '/', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/'}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                smart_toy
              </span>
              <span class="ml-2">{{ t('chatbots.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('dashboard')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/dashboard'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/dashboard')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                home
              </span>
              <span class="ml-2">{{ t('dashboard.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('chats')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/chats'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/chats')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                chat
              </span>
              <span class="ml-2">{{ t('chats.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('sources')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                description
              </span>
              <span class="ml-2">{{ t('sources.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid() && userHasPluginAvailable">
            <a (click)="changePage('plugins')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/plugins'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/plugins')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                extension
              </span>
              <span class="ml-2">{{ t('plugins.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('settings')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                settings
              </span>
              <span class="ml-2">{{ t('settings.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('embed')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/embed'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/embed')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                link
              </span>
              <span class="ml-2">{{ t('embed_on_site.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
        </ul>
        <ul class="list-none p-0 m-0 flex lg:align-items-center flex-column lg:flex-row select-none">
          <li *ngIf="urlContainsUuid()">
            <a (click)="openChat()" target="_blank"
              class="hover:text-900 hover:surface-100 cursor-pointer no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                open_in_new
              </span>
              <span class="ml-2">{{ t('open_chat') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  <div *ngIf="selectedAssistant" class="content-page px-4 py-2 md:px-6 lg:px-8">
    <ul class="list-none pl-0 pt-3 pb-2 m-0 flex align-items-center font-medium overflow-x-auto">
      <li class="pr-2">
        <a (click)="router.navigate(['/'])" class="cursor-pointer text-blue-500">
          <span class="material-symbols-sharp">
            home
          </span>
        </a>
      </li>
      <li class="px-1"><span class="text-500">/</span></li>
      <li class="px-1">
        <a class="text-600 white-space-nowrap">{{ t('chatbot') }}</a>
      </li>
      <li class="px-1"><span class="text-500">/</span></li>
      <li class="px-1"><a class="text-600 white-space-nowrap">{{ selectedAssistant.name }}</a></li>
    </ul>
  </div>
</ng-container>