import { Component, OnInit, Renderer2, isDevMode } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { MenuItem, MessageService } from 'primeng/api';
import { filter } from 'rxjs';
import { ApiService } from 'src/app/_utils/api/api.service';
import { StorageService } from 'src/app/_utils/storage/storage.service';
import { UtilityService } from 'src/app/_utils/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [MessageService]
})
export class TopbarComponent implements OnInit {
  profileOptions: MenuItem[] = [];
  langOptions: MenuItem[] = [];
  isFullScreen: boolean = false;
  assistants: any[] = [];
  selectedAssistant: any = null;
  user: any = {};
  isStaging: boolean = environment.staging;
  avatarLabel: string | null = null;
  activeLang: string;
  userHasPluginAvailable: boolean = false;

  constructor(private utilityService: UtilityService, private translocoService: TranslocoService, private renderer: Renderer2, private messageService: MessageService, private apiService: ApiService, protected router: Router, private storageService: StorageService, private route: ActivatedRoute) {
    this.user = this.storageService.get('user');
    this.avatarLabel = this.storageService.get('user')?.name?.charAt(0).toUpperCase();
    this.activeLang = this.translocoService.getActiveLang();
    this.translocoService.selectTranslate(['error', 'error_updating_lang', 'profile.account', 'profile.billing', 'logout', 'support', 'profile.usage']).subscribe(translations => {
      this.profileOptions = [
        {
          separator: true
        },
        {
          label: translations[2],
          command: (event) => this.router.navigate(['/user/profile'])
        },
        {
          label: translations[3],
          disabled: this.user?.admin != undefined && this.user?.admin == true,
          command: (event) => this.router.navigate(['/user/profile'], { fragment: 'billing' })
        },
        {
          label: translations[6],
          disabled: this.user?.admin != undefined && this.user?.admin == true,
          command: (event) => this.router.navigate(['/user/profile'], { fragment: 'usage' })
        },
        {
          label: translations[5],
          disabled: true
        },
        {
          separator: true
        },
        {
          label: translations[4],
          command: (event) => {
            this.storageService.delete('user');
            this.router.navigate(['/auth/login']);
          }
        }
      ];

      let generateMenuItemHtml = (lang: string, label: string) => {
        return {
          label: `<span class="flex align-items-center"><img src="/assets/flags/${lang}.png" width="22" alt="${lang} flag" draggable="false"><span class="ml-2">${label}</span></span>`,
          escape: false,
          disabled: this.translocoService.getActiveLang() == lang,
          command: (event: any) => {
            this.translocoService.setActiveLang(lang);
            this.apiService.updateUserInfo({ lang: lang }).subscribe(response => {
              if (!response.success || response.error) {
                console.error(response);
                this.messageService.add({ severity: 'error', summary: translations[0], detail: translations[1] });
              }
            });
          }
        }
      }

      this.translocoService.langChanges$.subscribe(lang => {
        this.activeLang = lang;
        // this is used to refresh active lang on menu dropdown
        this.langOptions = [generateMenuItemHtml('it', 'Italiano'), generateMenuItemHtml('en', 'English')];
      });
    });
  }

  ngOnInit(): void {
    //TODO: evitare di fare una doppia richiesta per ottenere i chatbot

    if (this.user?.admin == true) { // admin does not have chatbots
      return;
    }

    /*this.translocoService.selectTranslate(['error', 'error_fetching_data']).subscribe(translations => {
      this.utilityService.getAssistants().subscribe({
        next: (data) => {
          this.assistants = data;

          for (let i = 0; i < this.assistants.length; i++) {
            if (this.router.url.includes(this.assistants[i].uuid))
              this.assistantName = this.assistants[i].name;
          }

          this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            let status: boolean = false;
            for (let i = 0; i < this.assistants.length; i++) {
              if (event.urlAfterRedirects.includes(this.assistants[i].uuid)) {
                this.assistantName = this.assistants[i].name;
                status = true;
                break;
              }
              if (!status) this.assistantName = null;
            }
          });
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: translations[0], detail: error.message || translations[1] });
          console.error(error);
        },
      });
    });*/

    this.translocoService.selectTranslate(['error', 'error_fetching_data']).subscribe(translations => {
      this.apiService.getChatBots().subscribe((response) => {
        if (response.success) {
          this.assistants = response.data.assistants;
          this.userHasPluginAvailable = response.data.has_plugin_available;

          for (let i = 0; i < this.assistants.length; i++) {
            if (this.router.url.includes(this.assistants[i].uuid))
              this.selectedAssistant = this.assistants[i];
          }

          this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            let status: boolean = false;
            for (let i = 0; i < this.assistants.length; i++) {
              if (event.urlAfterRedirects.includes(this.assistants[i].uuid)) {
                this.selectedAssistant = this.assistants[i];
                status = true;
                break;
              }
              if (!status) this.selectedAssistant = null;
            }
          });
        } else {
          this.messageService.add({ severity: 'error', summary: translations[0], detail: translations[1] });
          console.error(response);
        }
      });
    });
  }

  openChat() {
    const uuid = this.router.url.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/);
    if (uuid) {
      window.open(environment.chatUrl + '/' + uuid, '_blank');
    } else {
      console.error('uuid not found');
    }
  }

  changePage(page: string) {
    const uuid = this.router.url.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/);
    if (uuid) {
      this.router.navigate(['chatbot', uuid[0], page]);
    } else {
      console.error('uuid not found');
    }
  }

  urlContainsUuid(): boolean {
    return this.router.url.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/) !== null
  }

  /**
   * toggles full screen mode
   */
  toggleFullScreen(): void {
    let document: any = window.document;

    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    this.isFullScreen = !this.isFullScreen;
  }
}
