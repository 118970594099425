import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private errors: any = null;

  constructor(private translocoService: TranslocoService, private http: HttpClient, private storageService: StorageService, private router: Router) {
    this.translocoService.selectTranslateObject('api_errors').subscribe((errors) => {
      this.errors = errors;
    });
    // Bind _handleErrors to the current instance
    this._handleErrors = this._handleErrors.bind(this);
  }

  private _handleErrors(error: HttpErrorResponse): Observable<any> {
    let errorMessage: string;
    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (error.error?.errors?.length > 0)
      errorMessage = error.error.errors.join(', ');
    else
      errorMessage = this.errors['code-' + error.status] || this.errors.general_with_code.replace('%code%', error.status);

    if (error.status && error.status === 401) {
      this.storageService.delete('token');
      this.router.navigate(['/auth/login']);
    }
    
    return of({ success: false, error: true, errors: [errorMessage] });
  }

  login(username: string, password: string, admin: boolean = false): Observable<any> {
    return this.http.post(environment.apiUrl + (admin ? '/admin/auth/login' : '/auth/login'), { username, password }).pipe(catchError(this._handleErrors));
  }

  getModels(): Observable<any> {
    return this.http.get(environment.apiUrl + '/models', {
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getFineTuningModels(company: string, api_key: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/models/fine-tuning', {
      params: {
        company: company,
      },
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token,
        'api-key': api_key
      }
    }).pipe(catchError(this._handleErrors));
  }

  sendResetPasswordRequest(username: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/request-reset-password', { username }).pipe(catchError(this._handleErrors));
  }

  sendResetPassword(token: string, password: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/reset-password/' + token, password).pipe(catchError(this._handleErrors));
  }

  getChatBots(): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getUsers(): Observable<any> {
    return this.http.get(environment.apiUrl + '/admin/user', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  createAssistant(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant', data, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getChatBot(assistant: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getDashboard(assistant: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/dashboard', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getKnownladge(assistant: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/knowledge', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  uploadDocument(assistant: string, formData: FormData): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/file', formData, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  deleteDocument(assistant: string, uuid: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/assistant/' + assistant + '/document/' + uuid, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  addUrl(assistant: string, url: string[]): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/url', url, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  saveAssistant(assistant: string, data: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/assistant/' + assistant, data, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  deleteAssistant(assistant: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/assistant/' + assistant, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  connectToDrive(assistant: string, code: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/drive', { code }, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  deleteDriveFolder(assistant: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/assistant/' + assistant + '/drive', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getChats(assistant: string, page: number = 1): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/chat?page=' + page, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getMessages(assistant: string, chat: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/chat/' + chat + '/message', {
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  createGoogleDriveFile(assistant: string, fileType: string, fileName: string | undefined = undefined): Observable<any> {
    return this.http.put(environment.apiUrl + '/assistant/' + assistant + '/drive/file', { fileType, fileName }, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  refreshGoogleDriveFile(assistant: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/drive/refresh', {}, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  addQuestion(assistant: string, question: string, answer: string, message_uuid: string | null = null): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/question', { question, answer, message_uuid }, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  adminCreateUser(user: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/admin/user', user, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  adminDeleteUser(uuid: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/admin/user/' + uuid, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  adminUpdateUser(uuid: string, user: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/admin/user/' + uuid, user, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getUserInfo(): Observable<any> {
    return this.http.get(environment.apiUrl + '/user/profile', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  updateUserInfo(data: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/user/profile', data, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  requestDeleteAccount(): Observable<any> {
    return this.http.post(environment.apiUrl + '/user/delete-account', {}, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  adminGetTools(): Observable<any> {
    return this.http.get(environment.apiUrl + '/admin/tool', {
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getTools(assistant: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/tool', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  activeTool(assistant: string, tool: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/assistant/' + assistant + '/tool/' + tool + '/activate', {}, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  adminUpdateAssistant(assistant: string, data: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/admin/assistant/' + assistant, data, { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }

  getDriveFileTypes(assistant: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/assistant/' + assistant + '/drive/file-types', { 
      headers: {
        'Authorization': 'Bearer ' + this.storageService.get('user')?.token
      }
    }).pipe(catchError(this._handleErrors));
  }
}
