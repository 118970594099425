import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AIModels } from 'src/app/app.config';
import { SelectItemGroup } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private models$: Observable<SelectItemGroup[]> | null = null;
  private assistants$: Observable<any[]> | null = null;

  private assistants: any[] = [];

  constructor(private apiService: ApiService) {}

  getAssistants(): Observable<any> {
    if (this.assistants$) {
      // Se l'observable è già presente, restituiscilo
      return this.assistants$;
    } else if (this.assistants.length > 0) {
      return of(this.assistants);
    } else {
      // Effettua la chiamata API una sola volta e memorizza il risultato in un observable condiviso
      this.assistants$ = this.apiService.getChatBots().pipe(
        tap((response) => {
          if (response.success) {
            this.assistants = response.data;
            console.log(this.assistants);
          } else {
            throw new Error(response.error.join(", "));
          }
        }),
        map(() => this.assistants),
        shareReplay(1) // Condivide l'ultimo valore tra tutte le iscrizioni
      );
      return this.assistants$;
    }
  }

  getModels(): Observable<SelectItemGroup[]> {
    if (this.models$) {
      // Se l'observable è già presente, restituiscilo
      return this.models$;
    } else if (AIModels.length > 0) {
      // Se AIModels è già stato caricato, restituisci un observable immediato
      return of(AIModels);
    } else {
      // Effettua la chiamata API una sola volta e memorizza il risultato in un observable condiviso
      this.models$ = this.apiService.getModels().pipe(
        tap((response) => {
          if (response.success) {
            response.data.forEach((company: any) => {
              let obj: SelectItemGroup = { label: company.company, items: [] };
              company.models.forEach((model: any) => {
                obj.items.push({ label: model.name, value: model.value });
              });
              AIModels.push(obj);
            });
          }
        }), 
        map(() => AIModels), // Mappa la risposta in AIModels
        shareReplay(1) // Condivide l'ultimo valore (AIModels) tra tutte le iscrizioni
      );
      return this.models$;
    }
  }

  getModelName(model: string): string {
    if (model.includes('company:') && model.includes('/model:')) {
      const match = model.match(/company:[^/]+\/model:(.+)/);
      return match ? match[1] : 'Undefined';
    }
    return model;
  }

  getCompanyFromModel(model: string | null, models: any): string {
    if (model === null)
      return 'OpenAI';

    if (model.includes('company:') && model.includes('/model:')) {
      const match = model.match(/company:([^/]+)\/model:([^/]+)/);
      return match ? match[1] : '';
    }

    let company = '';
    models.forEach((group: any) => {
      group.items.forEach((item: any) => {
        if (item.value === model)
          company = group.label;
      });
    });
    return company;
  }
}
